import React, { useMemo } from 'react';
import './Header.less';
import { Container, Control, ControlMenu, ControlMenuProps, Flag, Icon, IconProps, ImageProps, Images, List, Popover } from 'components';
import { Color, Message } from 'interfaces';
import { Translate, useTranslate } from 'providers';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

export type HeaderPopoverProps = {
  label: Message;
  icon?: IconProps;
  content?: React.ReactNode;
  noShrink?: boolean;
};

type HeaderMultiSelectItem = {
  title: Message | React.ReactNode;
  subtitle?: Message | React.ReactNode;
  flag?: Color | Color[];
};

export type HeaderMultiSelect = {
  items: HeaderMultiSelectItem[];
  selected: number;
  onSelect: (idx: number) => void;
};

type HeaderTitleProps = {
  title: Message | React.ReactNode;
  subtitle?: Message | React.ReactNode;
  backUrl?: string;
  wrapTitle?: boolean;
  multiSelect?: HeaderMultiSelect;
};

export type HeaderProps = HeaderTitleProps & ControlMenuProps & {
  flag?: Color | Color[];
  backgroundFlag?: boolean;
  className?: string;
  images?: ImageProps[];
  popovers?: HeaderPopoverProps[];
  hideControlsMobile?: boolean;
};

const HeaderTitle: React.FC<HeaderTitleProps> = React.memo(({ title, subtitle, backUrl, wrapTitle, multiSelect }) => {

  const translate = useTranslate();

  const renderTitle = () => {

    const renderedTitle = translate(title);

    if (multiSelect?.items?.length > 1) {
      const list = (
        <Container scrollY className={'header-title-multi-select-content'}>
          <List
            items={multiSelect.items.map((item, idx) => ({
              id: idx,
              title: item.title,
              subtitle: item.subtitle,
              flag: item.flag,
              highlighted: idx === (multiSelect.selected || 0),
            }))}
            onItemClick={item => multiSelect.onSelect(item.id as number)}
          />
        </Container>
      );
      return (
        <Popover content={list} placement={'bottomLeft'}>
          <span className={'header-title-multi-select'}>
            {renderedTitle}<Icon icon={faAngleDown}/>
          </span>
        </Popover>
      );

    }
    return renderedTitle;
  };

  const backUrlControl = useMemo(() => backUrl && (
    <span className={'header-close'}>
      <Control
        icon={faTimes}
        linkTo={backUrl}
        isActive={false}
      />
    </span>
  ), [backUrl]);

  return (
    <Container className={'header-title'} horizontal>
      <h1 className={cx({ 'header-wrap-title': wrapTitle })}>{renderTitle()}</h1>
      {subtitle && <span className={'header-subtitle'}><Translate message={subtitle}/></span>}
      {backUrlControl}
    </Container>
  );
});

export const Header = (props: HeaderProps) => {

  const { flag, backgroundFlag, title, images, subtitle, controls, backUrl, maxVisibleControls, hideControlsMobile, multiSelect } = props;

  const className = useMemo(() => cx('header', { 'header-background-flag': flag && backgroundFlag }, props.className), [props.className, backgroundFlag, flag]);

  const menu = useMemo(() => (
    <ControlMenu
      controls={controls}
      maxVisibleControls={maxVisibleControls}
      className={cx('header-controls', { 'hide-mobile': hideControlsMobile })}
    />
  ), [controls, maxVisibleControls, hideControlsMobile]);

  const popovers = useMemo(() => props.popovers?.length > 0
    ? (
      <Container horizontal grow shrink className={'header-popover-wrapper'}>
        {props.popovers?.map((popover, index) => (
          <Popover key={index} content={popover.content} placement={'bottom'}>
            <a
              className={cx('header-popover-trigger', { 'no-content': !popover.content }, { 'no-shrink': popover.noShrink })}
              style={popover.noShrink ? undefined : { maxWidth: `${100 / props.popovers.length}%` }}
            >
              {popover.icon && <Icon {...popover.icon}/>}
              <span>
                <Translate message={popover.label}/>
              </span>
            </a>
          </Popover>
        ))}
      </Container>
    )
    : null, [props.popovers]);

  return (

    <Container horizontal className={className}>

      {flag && (
        <Flag colors={flag} className={'header-flag'}/>
      )}

      {images && (
        <Container className={'header-images'}>
          <Images items={images}/>
        </Container>
      )}

      <Container grow className={'header-content'}>

        <HeaderTitle title={title} subtitle={subtitle} wrapTitle={props.wrapTitle} backUrl={backUrl} multiSelect={multiSelect}/>

        {(props.popovers?.length > 0 || controls?.length > 0) && (
          <Container horizontal className={'header-control-wrapper'}>
            {popovers}
            {menu}
          </Container>
        )}

      </Container>

    </Container>
  );
};
