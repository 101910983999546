import React, { useCallback, useEffect } from 'react';
import { useBasketContext, useListLayoutContext } from 'modules/orders/containers/OrderWizard/providers';
import constate from 'constate';
import {
  AggregatedProfileProperties,
  AggregatedProfileType,
  AggregatedRequirementFlags,
  AggregatedSuperRequirementProperties,
  Feature,
  OrderWizardLocalization,
  OrderWizardRequirement,
  RequirementAggregationType,
  RequirementType,
} from 'interfaces/api';
import { IconProps, ListItem } from 'components';
import {
  faBug,
  faClipboardMedical,
  faCommentAlt,
  faCommentAltCheck,
  faCommentAltExclamation,
  faEuroSign,
  faFileCheck,
  faGrid,
  faHand,
  faLayerGroup,
  faLink,
  faLocationCheck,
  faLocationPen,
  faStethoscope,
} from '@fortawesome/pro-light-svg-icons';
import { faChfSign, faStar as faStarFull } from '@fortawesome/pro-solid-svg-icons';
import { Color } from 'interfaces';
import { useGuard } from 'containers';
import messages from 'messages';
import cx from 'classnames';
import { useIncompatibleRequirements, useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import {
  freeTextByType,
  getBadgeForRequirement,
  isProfile,
  isSuperRequirement,
  useGetRequirementBasketForm,
  useIsRequirementPrivateOnly,
  useRequirementSubtitle,
} from 'modules/orders/containers/OrderWizard/utils';
import { find, flatten, includes } from 'lodash';
import { getRequirementId, getRequirementShortName } from 'modules/orders/utils';
import { faExclamationCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

type ListItemType = OrderWizardRequirement | AggregatedProfileProperties;

const useOrderWizardContext = () => {

  const { getFlag, history, immutableRequirementIds, currentOrder, isPoolMode } = useOrdersContext();

  const guard = useGuard();

  const { wizardSettings, officeDoctor } = useOfficeDoctorContext();
  const { getListLayoutArgs } = useListLayoutContext();
  const getIncompatibleRequirements = useIncompatibleRequirements();

  const {
    basketRequirements,
    profileRequirementsAllInBasket,
    profileRequirementsPartialInBasket,
    subRequirementsAllInBasket,
    subRequirementsPartialInBasket,
    poolRequirementsPartialInBasket,
    poolRequirementsAllInBasket,
    inBasketAsDuplicateRequirement,
  } = useBasketContext();

  const createSubtitle = useRequirementSubtitle();

  // update list data
  const updateData = useCallback(() => {

    const { context, bindings } = getListLayoutArgs();

    if (context?.data) {
      context.data.forEach((item: ListItem<OrderWizardRequirement>) => {
        bindings.updateItem(getRequirementId(item.meta), item.meta);
      });

    }
  }, [getListLayoutArgs]);

  useEffect(() => updateData, [history?.length, basketRequirements.map(r => r.id).join(','), getIncompatibleRequirements]);

  const freeTextFeatureAllowed = guard({ feature: Feature.RequirementFreeText }, () => true);

  // convertProfileToListItem
  const convertProfileToListItem = useCallback((profile: AggregatedProfileProperties): ListItem<AggregatedProfileProperties> => {

    const filterString = JSON.stringify(profile.filter);

    return {
      icons: [{ icon: faLayerGroup, color: Color.Smoke }],
      title: profile.longName,
      id: getRequirementId(profile),
      className: cx('list-item-profile', {
        'list-item-global-profile': profile.entityType === AggregatedProfileType.Global,
        'list-item-requirement-collection': profile.entityType === AggregatedProfileType.RequirementCollection,
        'list-item-partial-in-basket': profileRequirementsPartialInBasket(profile),
      }),
      highlighted: isProfile && profileRequirementsAllInBasket(profile),
      subtitle: (
        <>
          {profile.shortName}
        </>
      ),
      meta: profile,
      groupByValue: filterString,
      faded: flatten(profile.requirements.map(getIncompatibleRequirements)).length > 0,
      style: profile.color
        ? {
          '--oa-filter-profiles-color': '#' + profile.color,
        }
        : undefined,
    } as ListItem;
  }, [profileRequirementsPartialInBasket, profileRequirementsAllInBasket]);

  // convertSuperRequirementToListItem
  const convertSuperRequirementToListItem = useCallback((requirement: AggregatedSuperRequirementProperties): ListItem<AggregatedSuperRequirementProperties> => {

    const filterString = JSON.stringify(requirement.filter);

    return {
      icons: [{ icon: faGrid, color: Color.Smoke }],
      title: requirement.longName,
      id: getRequirementId(requirement),
      className: cx('list-item-requirement-collection', {
        'list-item-partial-in-basket': subRequirementsPartialInBasket(requirement),
      }),
      highlighted: isSuperRequirement && subRequirementsAllInBasket(requirement),
      subtitle: (
        <>
          {requirement.shortName}
        </>
      ),
      meta: requirement,
      groupByValue: filterString,
    };
  }, [subRequirementsPartialInBasket, subRequirementsPartialInBasket]);

  const getCurrencyIcon = useCallback(() => {
    if (officeDoctor?.localisation === OrderWizardLocalization.CHE) {
      return faChfSign;
    }
    return faEuroSign;
  }, [officeDoctor?.localisation]);

  const isRequirementPrivateOnly = useIsRequirementPrivateOnly();
  const getRequirementBasketForm = useGetRequirementBasketForm();

  // convertToListItem
  const convertToListItem = useCallback((requirement: ListItemType, basket?: boolean): ListItem<ListItemType> => {

    if (isProfile(requirement)) {
      return convertProfileToListItem(requirement);
    }

    if (isSuperRequirement(requirement)) {
      const superRequirement = find(wizardSettings.superRequirements, { entityId: requirement.entityId });
      return convertSuperRequirementToListItem({ ...requirement, shortName: superRequirement?.shortName || requirement.shortName });
    }

    const isIncompatible = basket && !isPoolMode && currentOrder?.costUnit && requirement.form?.costUnit !== currentOrder?.costUnit;

    const icons: IconProps[] = [];

    if (isIncompatible) {
      icons.push({ icon: faExclamationCircle, color: Color.Yellow });
    }

    if (requirement.ruleInfoText) {
      icons.push(faFileCheck);
    }

    if (requirement.hint && guard({ feature: Feature.RequirementsHint }, () => true)) {
      icons.push(faClipboardMedical);
    }

    if (requirement.infoUrl) {
      icons.push(faLink);
    }

    if (freeTextFeatureAllowed && requirement.freeTextAllowed) {
      if (freeTextByType(requirement)) {
        icons.push(faCommentAltCheck);
      } else if (requirement.freeTextMandatory) {
        if (basket) {
          icons.push({ icon: faCommentAltExclamation, color: Color.Red });
        } else {
          icons.push(faCommentAltExclamation);
        }
      } else {
        icons.push(faCommentAlt);
      }
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.AskOrigin)) {
      icons.push(faLocationCheck);
    }

    if (requirement.diagnoses?.length > 0 && guard({ feature: Feature.RequirementDiagnoses }, () => true)) {
      icons.push(faStethoscope);
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.AskOriginOptional)) {
      icons.push(requirement.origin ? faLocationCheck : faLocationPen);
    }

    if ((isRequirementPrivateOnly(requirement) || getRequirementBasketForm(requirement)?.isPrivate) && requirement.isCharged) {
      icons.push(getCurrencyIcon());
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.IsPinned)) {
      icons.push({ icon: faStarFull, color: Color.Blue });
    }

    let title = requirement.longName;

    if (requirement.entityType === RequirementType.Microbiological) {
      icons.push({ icon: faBug });

      if (wizardSettings?.preferences?.orderWizardCompactView && !wizardSettings?.preferences?.orderWizardHideBakMaterialGroupInfo) {
        title += ` (${getRequirementShortName(requirement)})`;
      }
    }

    if (requirement.cancelled_at) {
      icons.push({
        icon: faTimesCircle, color: Color.Red,
        tooltip: messages.orders.controls.cancelRequirement.isCancelled,
      });
    }

    const isImmutable = includes(immutableRequirementIds, requirement.id);
    if (isImmutable) {
      icons.push({ icon: faHand });
    }

    requirement.selectedAnalyses = requirement.filter?.type === RequirementAggregationType.Analysis && !basket
      ? requirement.analyses.filter(a => a.id === requirement.filter.id).map(a => a.shortName)
      : requirement.selectedAnalyses;

    const filterString = JSON.stringify(requirement.filter);

    const showAdditionalText = !basket && requirement.additionalText && !wizardSettings?.preferences?.orderWizardCompactView;

    return {
      icons,
      title,
      id: getRequirementId(requirement),
      flag: getFlag(requirement),
      className: cx({
        'list-item-mibi': requirement.entityType === RequirementType.Microbiological,
        'list-item-immutable': isImmutable,
        'list-item-incompatible-cost-unit': isIncompatible,
        'list-item-partial-in-basket': poolRequirementsPartialInBasket(requirement),
      }),
      badge: wizardSettings?.preferences?.orderWizardPopoverHideAnalyses ? undefined : getBadgeForRequirement(requirement),
      highlighted: poolRequirementsAllInBasket(requirement),
      subtitle: createSubtitle(requirement),
      meta: requirement,
      groupByValue: filterString,
      backgroundColor: requirement.entityType === RequirementType.Microbiological ? undefined : requirement.filter?.background,
      immutable: isImmutable,
      faded: getIncompatibleRequirements(requirement).length > 0 || isIncompatible || isImmutable || inBasketAsDuplicateRequirement(requirement).length > 0,
      body: showAdditionalText ? <div dangerouslySetInnerHTML={{ __html: requirement.additionalText }}/> : undefined,
    };
  }, [
    getFlag,
    basketRequirements,
    getIncompatibleRequirements,
    poolRequirementsPartialInBasket,
    poolRequirementsAllInBasket,
    getBadgeForRequirement,
    immutableRequirementIds,
    inBasketAsDuplicateRequirement,
    currentOrder,
    isPoolMode,
  ]);

  return {
    convertToListItem,
    updateData,
    convertSuperRequirementToListItem,
  };
};

const [
  ContextProvider,
  useContextFunctions,
] = constate(useOrderWizardContext);

export {
  ContextProvider,
  useContextFunctions,
};
