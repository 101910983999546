import React, { useState } from 'react';
import { Control, DatePicker, HelgaContainer, HelgaImageType } from 'components';
import messages from 'messages';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { OrderWizardRequirement } from 'interfaces/api';
import { Input } from 'antd';
import dayjs from 'dayjs';
import { getLongDateFormat } from 'utils/helpers';
import { useTranslate } from 'providers';

export type FreetextModalProps = {
  requirement: OrderWizardRequirement;
  next: (requirement: OrderWizardRequirement) => void;
  cancel?: () => void;
  loading?: boolean;
};

export const FreetextEdit: React.FC<FreetextModalProps> = ({ requirement, next, cancel, loading }) => {

  const translate = useTranslate();
  const freeTextAsDate = requirement?.freeTextAsDate;

  const { title, text, placeholder } = messages.orders.requirementOptions[freeTextAsDate ? 'freeTextDate' : 'freeText'];

  const [value, setValue] = useState<string>(freeTextAsDate ? requirement?.freeTextDate : requirement?.freeText);

  return (
    <HelgaContainer
      title={translate(title, { name: requirement?.longName })}
      text={text}
      image={HelgaImageType.SelectAbc}
      buttons={[(
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
        />
      ), (
        <Control
          icon={faArrowRight}
          label={messages.general.next}
          onClick={() => next({
            ...requirement,
            freeText: freeTextAsDate ? null : value,
            freeTextDate: freeTextAsDate ? value : null,
          })}
          button={{
            loading,
            disabled: !value?.length,
            type: 'primary',
          }}
        />
      )]}
    >

      {!requirement?.freeTextAsDate && (
        <div className={'content'}>
          <Input
            autoFocus
            value={value}
            placeholder={translate(placeholder)}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </div>
      )}

      {requirement?.freeTextAsDate && (
        <div className={'content'}>
          <DatePicker
            value={value ? dayjs(value) : null}
            autoFocus
            format={getLongDateFormat()}
            placeholder={translate(
              placeholder,
              { format: getLongDateFormat().toLowerCase() },
            )}
            onChange={(e) => {
              setValue(e ? e.format('YYYYMMDD') : null);
            }}
          />
        </div>
      )}

    </HelgaContainer>
  );
};
